import { useState } from 'react';
import './App.css';
import DetailModal from './components/DetailModal';

function App() {

  const [isClosedExpanded, setIsClosedExpanded] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(true)

  const openReminderCard = <div className="transition ease-in-out cursor-pointer bg-white rounded-lg py-2 px-3 border-2 border-white mb-2 hover:border-gray-200" onClick={() => setIsModalOpen(true)}>
    <div>Buy bread and milk</div>
    <div>
      <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">NUS</span>
      <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">⏰ in 3 hours</span>
      <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">🔁 every hour</span>

    </div>
  </div>

  const closedReminderCard = <div className="transition ease-in-out cursor-pointer bg-white rounded-lg py-2 px-3 border-2 border-white mb-2 hover:border-gray-200" onClick={() => setIsModalOpen(true)}>
    <div className='opacity-50'>
      <div className='line-through'>hello</div>
      <div>
        <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">NUS</span>
      </div>
    </div>
  </div>

  return (
    <div className=''>
      <DetailModal show={isModalOpen} closeDialog={() => setIsModalOpen(false)} />
      <nav className='shadow'>
        <div className="container mx-auto py-3 px-4 text-center">
          <span className='text-lg font-semibold text-gray-400'>
            rereminders
          </span>
        </div>
      </nav>

      <div className='container mx-auto p-4 grid grid-cols-6 gap-4'>
        <div className='hidden lg:block'>
          <div className='bg-gray-100 p-4 rounded-lg'>
            <span className='text-gray-600 font-semibold'>
              Filters
            </span>
          </div>
        </div>
        <div className='lg:col-span-4 col-span-6'>
          <div className='bg-gray-100 p-4 rounded-lg mb-3'>
            <div className='text-gray-600 font-semibold mb-3'>
              Open Reminders
              <span class="bg-yellow-100 text-yellow-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded">3</span>
            </div>
            <div>
              {[0, 1, 2].map(() => openReminderCard)}
            </div>
          </div>

          <div className='bg-gray-100 p-4 rounded-lg'>
            <div className='text-gray-600 font-semibold flex justify-between'>
              <div>
                Closed Reminders
                <span class="bg-yellow-100 text-yellow-800 text-xs font-medium ml-2 px-2.5 py-0.5 rounded">3</span>
              </div>
              <button
                class="text-gray-500 px-2 py-2 hover:bg-gray-200 rounded-full"
                type="button"
                onClick={() => setIsClosedExpanded(!isClosedExpanded)}
              >
                <svg class="w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
              </button>
            </div>
            {isClosedExpanded && <div className='my-3'>
              {[0, 1, 2].map(() => closedReminderCard)}
            </div>}

          </div>
        </div>
        <div className='hidden lg:block'>
          <div className='bg-gray-100 p-4 rounded-lg'>
            <div className='text-gray-600 font-semibold mb-3'>
              Actions
            </div>
            <button type="button" className="transition ease-in-out border-2 border-green-500 bg-white text-green-500 hover:bg-green-500 hover:text-white font-medium rounded-full text-sm w-full px-4 py-2 text-center">New Reminder</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
